<template>
  <div class="tag-input-container rounded-sm" @click="focusInput">
    <span v-for="tag in value" :key="tag" class="pl-2 bg-light rounded-sm ml-2">
      {{ tag }}
      <button class="bg-light text-muted rounded-sm border-0" @click.stop="removeTag(tag)">
        <i class="fa fa-times"></i>
      </button>
    </span>
    <input
      ref="tagInput"
      v-model="newTag"
      class="form-control pl-2"
      :class="{ 'ml-1': value.length > 0 }"
      type="text"
      :placeholder="placeholder"
      :disabled="disabled"
      @input="onInput"
      @keydown.enter.prevent="addTag"
      @keydown.backspace="handleBackspace"
    />
  </div>
</template>

<script>
import { debounce } from 'lodash';

import { uniq } from 'lodash';

export default {
  name: 'TagManager',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Add tags...'
    }
  },
  data() {
    return {
      newTag: ''
    };
  },
  mounted() {
    document.addEventListener('click', this.addTag);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.addTag);
  },
  methods: {
    onInput: debounce(function () {
      this.addTag();
    }, 1500),
    addTag() {
      let newTags = this.value;

      if (this.newTag.includes(',')) {
        const tags = this.newTag.split(',');
        newTags = uniq(tags).reduce((acc, tag) => {
          const newTag = tag.trim();
          if (!tag || acc.includes(tag)) return acc;
          this.$emit('itemAdded', newTag);
          return [...acc, newTag];
        }, this.value);
      } else {
        const tag = this.newTag.trim();
        if (!tag || this.value.includes(tag)) return;
        newTags = [...this.value, tag];
        this.$emit('itemAdded', tag);
      }

      this.$emit('input', newTags);
      this.newTag = '';
    },
    removeTag(tag) {
      const newValue = [...this.value].filter(value => value !== tag);
      this.$emit('input', newValue);
      this.$emit('itemRemoved', tag);
    },
    removeAllTags() {
      this.$emit('input', []);
    },
    handleBackspace() {
      if (!this.newTag && this.value.length > 0) {
        const newValue = [...this.value];
        const tag = newValue.pop();
        this.$emit('input', newValue);
        this.$emit('itemRemoved', tag);
      }
    },
    focusInput() {
      this.$refs.tagInput.focus();
    }
  }
};
</script>

<style scoped>
.tag-input-container {
  display: flex;
  flex-wrap: wrap;
  border: 2px solid #f0f3f8;
  align-items: center;
}
.tag-input-container input {
  flex: 1;
  border: none;
  outline: none;
}
</style>
